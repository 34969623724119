import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import { imageMapping, pressContent } from "../../utils/mediaContentForDynamic";

import "./media.scss";

const Press =()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for athletes and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA athlete-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL athlete comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <h1 className="text-center pb-4">Press Releases</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            
              <div className="pt-4">
                  {
                    pressContent.map((card, index) => (
                      <div className="pr-card pb-4" key={index}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="pr-img">
                              <img src={imageMapping[card.src]} className="mw-100" alt="Card visual" />
                            </div>
                          </div>
                          <div className="col-md-6 py-4">
                            <h3>{card.title}</h3>
                            <div className="pb-2 date">{card.date}</div>
                            <p>{card.content}</p>
                            <a href={card.readMoreLink} target="_blank" className="read-more">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
              </div>
              
          </Box>
        </div>
      </div>
      <Footer />
    </div>
    )
}
export default Press;