import React, { useState, useEffect, useRef } from "react";
import "./home.scss";

const HoverVideoDisplay = ({ imageSrc, videoSrc, isMobile, id, onVideoStateChange, isPlaying}) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isLargestInView, setIsLargestInView] = useState(false);
 
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      
      ([entry]) => {
        
        // Check if the element is visible and calculate visibility area
        if (entry.isIntersecting) {
          const elementArea = entry.boundingClientRect.width * entry.boundingClientRect.height;
          const viewportArea = window.innerWidth * window.innerHeight;

          // Determine if the element occupies more than 80% of the viewport
          const isMaxInView = elementArea / viewportArea >= 0.8;
          setIsLargestInView(isMaxInView); // Element occupies maximum area
        } 
        else {
          setIsClicked(false);
          setIsLargestInView(false);
        }
      },
      {
        threshold: 0.1, // Trigger when at least 10% of the element is visible
      }
    );

    const currentElement = videoContainerRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const handleClick = () => {
    setIsClicked(true);
    if (onVideoStateChange) {
      onVideoStateChange(id, "playing");
    }
  };

  const handleVideoEnd = () => {
    setIsClicked(false);
    if (onVideoStateChange) {
      onVideoStateChange(id, "ended");
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        setIsClicked(false);
      }
    }
  }, [isPlaying]);

  return (
    <>
      {!isMobile && (
        <div
          className="city position-relative d-flex justify-content-center align-items-center hover-container"
          onMouseEnter={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
          }}
        >
          {/* Display Image */}
          {!isHovered && <img src={imageSrc} alt="Hover to see video" />}

          {/* Display Video */}
          {isHovered && (
            <video
             autoPlay
              muted
              controls 
              preload="auto" 
              poster={imageSrc}
              >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}

      {isMobile && (
        <div
          ref={videoContainerRef}
          id={`video-container-${id}`} 
          className="city position-relative d-flex justify-content-center align-items-center mobile-container"
        >
          {/* Display Image */}
          {!isClicked && (<>
            <button 
            onClick={handleClick}
            >
            <img src={imageSrc} alt="Hover to see video" />
            </button>
          </>
          )}

          {/* Display Video */}
        {(isClicked && isLargestInView) && (
            <video
              ref={videoRef}
              autoPlay
              controls
              onEnded={handleVideoEnd}
              poster={imageSrc}
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
    </>
  );
};

export default HoverVideoDisplay;


