import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {videos} from "../../utils/mediaContentForDynamic";
import "./media.scss";
import facebookIcon from "../../assets/facebook-icon.svg";
import twitterIcon from "../../assets/twitter-icon.svg";
import instagramIcon from "../../assets/instagram-icon.svg";
import youtubeIcon from "../../assets/youtube-icon.svg";
import tiktokIcon from "../../assets/tiktok-icon.svg";
import linkedinIcon from "../../assets/linkedin-icon.svg";

const Watch = () => {
  const [tabValue, setTabValue] = useState("2");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for athletes and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA athlete-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL athlete comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          {/* <h1 className="text-center pb-4">Watch</h1> */}
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  // variant="scrollable"
                  centered
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {/* <Tab label="Live" value="1" /> */}
                  {/* <Tab label="Videos" value="2" /> */}
                </TabList>
              </Box>
              {/* <TabPanel value="1">
                <div className="text-center">
                  <iframe
                    // width="100%"
                    // width="560" height="315" 
                    className="live-video"
                    src="https://www.youtube.com/embed/WdVqCi4f-IA?si=wvviCVj6jB0RMQkO"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </TabPanel> */}

              <TabPanel value="2">
              <div className="row gy-4 justify-content-center pb-4">
                  {videos.map((video, index) => (
                    <div className="col-md-6 text-center" key={index}>
                      <iframe
                        width="100%"
                        className="video"
                        src={video.src}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ))}
              </div>
                {/* <div className="row gy-4 justify-content-center pb-4">
                  <div className="col-md-6 text-center">
                    <iframe
                      width="100%"
                      className="video"
                      src="https://www.youtube.com/embed/hYUTm9gv2b4?si=JnfAOuNhCESoS5PQ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="col-md-6 text-center">
                    <iframe
                      width="100%"
                      className="video"
                      src="https://www.youtube.com/embed/BksW6aIScIU?si=w12USp1_l1mIT_SJ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="col-md-6 text-center">
                    <iframe
                      width="100%"
                      className="video"
                      src="https://www.youtube.com/embed/COvnTczdaxk?si=_s-hO6fobAZS2DFn"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div> */}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Watch;
