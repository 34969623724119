import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LondonIcon from "../../assets/london-icon.svg"
import DubaiIcon from "../../assets/dubai-icon.svg"
import SaoPauloIcon from "../../assets/sao-paulo-icon.svg"
import MiamiIcon from "../../assets/miami-icon.svg"
import LosAngelesIcon from "../../assets/los-angeles-icon.svg"
import NewYorkIcon from "../../assets/new-york-icon.svg"
import TeamRosterLondonBg from "../../assets/TR-london/bg.png"
import TeamRosterDubaiBg from "../../assets/TR-dubai/bg.png"
import TeamRosterSaoPauloBg from "../../assets/TR-sao-paulo/bg.png"
import TeamRosterMiamiBg from "../../assets/TR-miami/bg.png"
import TeamRosterLosAngelesBg from "../../assets/TR-los-angeles/bg.png"
import TeamRosterNewYorkBg from "../../assets/TR-new-york/bg.png"

import "./teams.scss";

const Teams = () => {
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>GFL MMA League | 2025 Athlete Comebacks & MMA's New Era</title>
        <meta
          property="og:title"
          content="GFL MMA League | 2025 Athlete Comebacks & MMA's New Era"
        />
        <meta
          name="description"
          content="Discover the GFL MMA League, launching in 2025 with top athletes returning to fight. Stay tuned for exclusive athlete updates, event announcements, and streaming details."
        />
        <meta
          name="keywords"
          content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA athletes 2025"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-md-8">
            <h2 className="text-center">4 Continents, 4 Nations, 6 Teams: <br></br>GFL 2025 Drafts 120 Athletes to Launch a New MMA Era</h2>
            </div>
          </div>
          

          {/* <section className="featured-teams pb-5">
            <div className="container">
              <div className="row g-md-5 gy-3">
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/London.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/Dubai.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/SaoPaulo.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/Miami.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/LA.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="team">
                    <img
                      src={require("../../assets/managers-coaches/NewYork.jpg")}
                      className="mw-100"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  centered
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {/* <Tab label="London" value="1" icon={<img src={LondonIcon} height={30} />} iconPosition="start" /> */}
                  <Tab label="London" value="1"/>
                  <Tab label="Dubai" value="2"/>
                  <Tab label="São Paulo" value="3"/>
                  <Tab label="Miami" value="4"/>
                  <Tab label="Los Angeles" value="5"/>
                  <Tab label="New York" value="6"/>
                </TabList>
              </Box>
              <TabPanel value="1">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-london/London.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterLondonBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-london/ltr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-london/ltr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value="2">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-dubai/Dubai.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterDubaiBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-dubai/dtr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-dubai/dtr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-sao-paulo/SaoPaulo.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterSaoPauloBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-sao-paulo/sptr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-sao-paulo/sptr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="4">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-miami/Miami.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterMiamiBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-miami/mtr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-miami/mtr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="5">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-los-angeles/LA.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterLosAngelesBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-los-angeles/latr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-los-angeles/latr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="6">
                <h2 className="text-center mb-5 team-heading1 position-relative">
                  <span>Team Management</span></h2>

                <div className="text-center pb-4">
                  <img
                    src={require("../../assets/TR-new-york/NewYork.jpg")}
                    className="team-managemnt"
                  ></img>
                </div>

                <h2 className="text-center my-5 team-heading1 position-relative">
                  <span>Team Roster</span></h2>
                <div className="team-roster" style={{ backgroundImage: `url(${TeamRosterNewYorkBg})`, }}>
                  {/* <div className="row justify-content-center pb-4">
                      <div className="col-md-4">
                        <img
                          src={require("../../assets/TR-new-york/nytr-img1.png")}
                          className="mw-100"
                        />
                      </div>
                    </div> */}
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img2.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img3.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img4.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img5.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img6.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img7.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img8.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img9.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-4 pb-4">
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img10.png")}
                        className="mw-100"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../assets/TR-new-york/nytr-img11.png")}
                        className="mw-100"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Teams;
