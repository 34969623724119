import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { imageMapping, newsContent, pressContent } from "../../utils/mediaContentForDynamic";

import "./media.scss";

const NewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const [tabValue, setTabValue] = useState("1");

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for athletes and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA athlete-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL athlete comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <h1 className="text-center pb-4">News</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {/* <TabContext value={tabValue}> */}
              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  // variant="scrollable"
                  centered
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Articles" value="1" />
                  <Tab label="Press Releases" value="2" />
                </TabList>
              </Box> */}
              {/* <TabPanel value="2">
              <div className="pt-4">
                  {
                    pressContent.map((card, index) => (
                      <div className="pr-card pb-4" key={index}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="pr-img">
                              <img src={imageMapping[card.src]} className="mw-100" alt="Card visual" />
                            </div>
                          </div>
                          <div className="col-md-6 py-4">
                            <h3>{card.title}</h3>
                            <div className="pb-2 date">{card.date}</div>
                            <p>{card.content}</p>
                            <a href={card.readMoreLink} target="_blank" className="read-more">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
              </div>
                
              </TabPanel> */}

              {/* <TabPanel value="1"> */}
              <div className="pt-4">
                  {newsContent.map((news, index) => (
                    <div className="news-card p-4 mb-4" key={index}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="news-img d-flex justify-content-center align-items-center">
                            <img src={imageMapping[news.src]} alt={news.title} />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <h3>{news.title}</h3>
                          <div className="pb-2 date">{news.date}</div>
                          <p>{news.content}</p>
                          <a href={news.readMoreLink} target="_blank" className="read-more" rel="noopener noreferrer">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
                
              {/* </TabPanel>
            </TabContext> */}
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default NewsPage;
