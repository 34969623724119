import React, { useCallback, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import "./contactForm.scss";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import { Link } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import SnackbarUI from '../common/snackbar';

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  // favoriteTeam: "",
};

const favoriteTeams = [
  {
    value: "-",
    label: "Select Favorite City",
  },
  {
    value: "LondonBullies",
    label: "London",
  },
  {
    value: "DubaiHunters",
    label: "Dubai",
  },
  {
    value: "SaoPauloOncas",
    label: "Sao Paulo",
  },
  {
    value: "MiamiDiablos",
    label: "Miami",
  },
  {
    value: "LosAngelesAssassins",
    label: "Los Angeles",
  },
  {
    value: "NewYorkForce",
    label: "New York",
  },
  // {
  //   value: "MontrealRush",
  //   label: "Montreal",
  // },
  
  
  // {
  //   value: "ParisMaquis",
  //   label: "Paris",
  // },
  
];

const ContactForm = ({openModal}) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [formSubmitted,setFormSubmitted] = useState(false)
  // const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState('');
  // const [severity,setSeverity] = useState('success')

  // const handleForm = useCallback((event) => {
  //   const { name, value, type, checked } = event.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));
  //   setErrors((prev) => ({ ...prev, [name]: "" }));
  // }, []);

  const handleForm = useCallback((event) => {
    console.log(event, "eventeventv");

    if (event.target) {
      const { name, value, type, checked } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else if (event) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: event
      }))
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  }, []);
  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    if (data.email == "") {
      errors.email = "Required";
    }
    if (!data.isChecked) {
      errors.isChecked = "Required";
    }
    if (
      data.favoriteTeam == "" ||
      data.favoriteTeam == "-" ||
      data.favoriteTeam == "Select Favourite Team"
    ) {
      errors.favoriteTeam = "Required";
    }
    return errors
  };

  const saveForm = (e) => {
    e.preventDefault();
    const validateErrors = validateFormData(formData);
    setErrors(validateErrors)

    const formdatacopy = { ...formData };

    if ('isChecked' in formdatacopy) {
        delete formdatacopy.isChecked;
    }
    if (Object.keys(validateErrors).length === 0) {
      axios.post(`${apiEndPoint}/contact-request`, formdatacopy, headers)
        .then((response) => {
          if (response) {
            setFormSubmitted(true)
            // setOpenSnack(true)
          }
        })
        .catch((e) => {
          // setOpenSnack(true)
          setContent("Something Went wrong !")
          // setSeverity("error")
        })
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      // favoriteTeam: "",
      isChecked: false,
    });
    setErrors({});
    setFormSubmitted(false);
  };

  return (
    <div>
      <div className="contact-form py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              {/* <h2>Stay in the Know for the GFL Draft!</h2> */}
              <h2>{openModal==true ? "Join the GFL Movement" : "Stay in the Know for the GFL Draft!"}</h2>
              <p>
                Sign up to receive exclusive updates, behind-the-scenes content,
                and all the latest announcements leading up to the first-ever
                Global Fight League Draft.
              </p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="contact-box p-4">
                {!formSubmitted ? <form>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="name"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleForm}
                      />
                    </FormControl>

                    {errors && errors.name && (
                      <p className="error mb-0 text-start">{errors.name}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="email"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleForm}
                      />
                    </FormControl>
                    {errors && errors.email && (
                      <p className="error mb-0 text-start">{errors.email}</p>
                    )}
                  </div>
                  {/* <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        name="phoneNumber"
                        placeholder="Phone Number*"
                        value={formData.phoneNumber}
                        onChange={handleForm}
                      />
                    </FormControl>
                  </div> */}
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <MuiTelInput
                        defaultCountry="US"
                        value={formData.phoneNumber}
                        onChange={handleForm}
                        placeholder="Phone Number*"
                        name="phoneNumber"
                        error={!!errors.phoneNumber} // Show error outline if there's an error
                      />
                    </FormControl>
                    {errors && errors.phoneNumber && (
                      <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="-"
                      placeholder="Select Favourite Team"
                      onChange={handleForm}
                      name="favoriteTeam"
                      className="w-100"
                    >
                      {favoriteTeams.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          defaultValue="Okay"
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors && errors.favoriteTeam && (
                      <p className="error mb-0 text-start">{errors.favoriteTeam}</p>
                    )}
                  </div>
                  <div>
                    <div className="d-flex align-items-center pb-3">
                      <Checkbox
                        type="checkbox"
                        name="isChecked"
                        checked={formData.isChecked}
                        onChange={(e) => handleForm(e)}
                      />
                      <label for="checkbox">
                        {" "}
                        Agree with <span><Link to={'/terms-of-service'} target="_blank">Terms</Link></span> and <span><Link to={'/privacy-policy'} target="_blank">Privacy and Policy</Link></span></label>
                    </div>
                    {errors.isChecked && (
                      <p
                        className="error mb-0 text-start ps-2"
                        style={{ position: "relative", top: "-20px" }}
                      >
                        {errors.isChecked}
                      </p>
                    )}
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 rounded-pill"
                    onClick={saveForm}
                  >
                    Submit
                  </Button>
                  <div className="text-center error2">{content.length!=0 && content}</div>
                </form> : (
                  <div className="thank-you-message text-center">
                    <h3 className="text-white">Thanks for joining the GFL community. Stay tuned for updates. </h3>
                    {/* <p className="text-white">We appreciate your interest and will be in touch soon.</p> */}
                    <Button
                      variant="primary"
                      className="mt-3 px-5"
                      onClick={resetForm}
                    >
                      Ok
                    </Button>
                  </div>
                )}
              </div>
            </div> 
          </div>
        </div>
      </div>
      {/* <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} /> */}
    </div>
  );
};

export default ContactForm;