import press1 from "../assets/PR-MMA-April-2025.png";
import press2 from "../assets/women-athletes.jpg";
import press3 from "../assets/announces6cities.jpg"
import press4 from "../assets/draft-press-release.png"
import press5 from "../assets/box-to-box-press-release.jpg"
import press6 from "../assets/silverside-press-release.jpg"


import managersCoaches from "../assets/gfl-press-release-cities-team-managers-coaches-16jan2025.pdf";
import gflDraft from "../assets/gfl-press-release-virtual-draft-24jan2025.pdf";
import boxtoBox from "../assets/gfl-press-release-box-to-box.pdf";
import silverside from "../assets/gfl-press-release-silverside.pdf";


import ctv from "../assets/CTV.png";
import usa from "../assets/usa-today-logo.png"
import low from "../assets/lowkick-mma-new.png"
import sucka from "../assets/mmasucka.png"
import mania from "../assets/mmamania.png"
import forbes from "../assets/forbes-logo.png"
import nytt from "../assets/mmanytt-logo.png"
import msn from "../assets/msn-logo.png"
import tpg from "../assets/tpg-logo.png"
import sportster from "../assets/thesportster.png"
import illus from "../assets/sports-illustrated-logo.png"
import yahoo from "../assets/yahoo-sports-logo.png"
import newsweek from "../assets/newsweek-logo.png"
import dog from "../assets/sherdog-logo.png"
import ring from "../assets/ringsideintel.png"
import mma from "../assets/mma-logo.png"


const imageMapping = {
    press1: press1,
    press2: press2,
    press3: press3,
    press4: press4,
    press5: press5,
    press6: press6,
    
    ctv:ctv,
    usa:usa,
    low:low,
    sucka:sucka,
    mania:mania,
    forbes:forbes,
    nytt:nytt,
    msn:msn,
    tpg:tpg,
    sportster:sportster,
    illus:illus,
    yahoo:yahoo,
    newsweek:newsweek,
    dog:dog,
    ring:ring,
    mma:mma
};


const videos = [
  {
    src:"https://www.youtube.com/embed/hYUTm9gv2b4?si=JnfAOuNhCESoS5PQ",
    title: "video1",
  },
  {
    src:"https://www.youtube.com/embed/BksW6aIScIU?si=w12USp1_l1mIT_SJ" ,
    title: "video2",
  },
  {
    src:"https://www.youtube.com/embed/COvnTczdaxk?si=_s-hO6fobAZS2DFn",
    title: "video1",
  },
]


const newsContent = [
  {
    src: "forbes",
    readMoreLink: "https://www.forbes.com/sites/brianmazique/2025/01/24/ufc-legend-and-future-hall-of-famer-has-signed-with-new-promotion/",
    title: "UFC Legend And Future Hall-Of-Famer Has Signed With New Promotion",
    date: "Jan 25, 2025, 03:05 AM EST",
    content: "UFC President Dana White said he wanted Tony Ferguson to retire.Ferguson declined, and it appears the former UFC interim lightweight champion has signed with the new Global Fight League (GFL), per the organization’s Instagram account.",
  },
  {
    src: "yahoo",
    readMoreLink: "https://sports.yahoo.com/live/gfl-draft-live-results-pick-updates-start-time-eligible-fighters-how-to-watch-live-stream-and-more-192257096.html",
    title: "GFL draft results: Full team rosters and picks; ex-UFC champ Tyron Woodley goes No. 1",
    date: "Jan 25, 2025",
    content: "Global Fight League (GFL) began its build toward a big MMA debut on Friday night. The new GFL promotion is expected to launch in April with a team format similar to the old IFL days. But before teams collide and fights are made, rosters have to be set. That's where the GFL draft came into play on Jan. 24.",
  },
  {
    src: "forbes",
    readMoreLink: "https://www.forbes.com/sites/brianmazique/2025/01/24/gfl-draft-results-every-fighter-drafted-for-the-new-mma-league/",
    title: "GFL Draft Results: Every Fighter Drafted For The New MMA League",
    date: "Jan 24, 2025, 10:53 PM EST",
    content: "The inaugural Global Fight League draft took place virtually on Friday, January 24. The draft saw dozens of UFC, Bellator, and Professional Fighters League veterans selected to the six MMA teams.",
  },
  {
    src: "usa",
    readMoreLink: "https://mmajunkie.usatoday.com/lists/global-fight-league-female-women-roster-signings-hannah-goldy-randi-field-paige-vanzant",
    title: "Global Fight League's draft-eligible female fighters list includes more than 75 across five divisions",
    date: "January 24, 2025 2:15 PM ET",
    content: "The new Global Fight League will hold its inaugural draft Friday, and more than 75 female fighters are eligible and on the list.",
  },
  {
    src: "usa",
    readMoreLink: "https://mmajunkie.usatoday.com/lists/global-fight-league-gfl-list-male-fighter-signings-ufc-mma-bellator-pfl",
    title: "Hundreds on Global Fight League's list of eligible male fighters ahead of inaugural draft",
    date: "January 24, 2025 2:00 PM ET",
    content: "The new GFL will hold its inaugural draft Friday, and hundreds of fighters will be on the list of eligible fighters. The GFL will be a team-based promotion that kicks off in April. Teams will be made up of both female and male fighters. Managers from six teams will take part in the draft.",
  },
  {
    src: "mma",
    readMoreLink: "https://www.mmafighting.com/2025/1/24/24350940/tony-ferguson-dillon-danis-headline-new-additions-to-gfl-draft",
    title: "Tony Ferguson, Dillon Danis headline new additions to GFL draft",
    date: "January 24, 2025 1:00 PM EST",
    content: "Global Fight League holds its official draft for its 2025 tournaments Friday, and a long list of notable names has been announced by the promotion as eligible to be selected.",
  },
  {
    src: "ring",
    readMoreLink: "https://ringsideintel.com/mma/lists/top-10-ufc-and-mma-great-fighting-in-gfl/",
    title: "10 UFC And MMA Greats You May Not Realize Will Soon Be Fighting For New GFL Promotion In 2025",
    date: "January 17, 2025",
    content: "The Global Fight League (GFL) is hoping to provide an alternative to the UFC. The promotion with a team-based format will kick things off in 2025 with an upcoming draft from their pool of fighters.",
  },
  {
    src: "usa",
    readMoreLink: "https://mmajunkie.usatoday.com/2025/01/global-fight-league-team-cities-managers-coaches",
    title: "GFL announces new draft entrees, managers, coaches,and team cities",
    date: "January 16, 2025 12:40 PM ET",
    content: "The fledgling Global Fight League continued to get its business in order Thursday with several important announcements.",
  },
  {
    src: "forbes",
    readMoreLink: "https://www.forbes.com/sites/brianmazique/2025/01/10/new-mma-promotion-increases-weight-class-limits-for-divisions/",
    title: "New MMA Promotion Increases Weight-Class Limits For Divisions",
    date: "Jan 10, 2025,03:28 AM EST",
    content: "It seems every day, a new and interesting detail about the Global Fight League (GFL) is revealed. On Thursday, the league’s official X account shared its weight classes with higher weight limits across the board.",
  },
  {
    src: "usa",
    readMoreLink: "https://mmajunkie.usatoday.com/2025/01/kevin-lee-rematch-tony-ferguson-gfl",
    title: "Kevin Lee interested to rematch Tony Ferguson in fledgling GFL",
    date: "January 10, 2025 12:10 PM ET",
    content: "With GFL his new home, Kevin Lee hopes he can finally run things back with a former rival.",
  },
  {
    src: "mania",
    readMoreLink: "https://www.mmamania.com/2025/1/7/24338479/longtime-ufc-vet-tony-ferguson-in-talks-gfl-ahead-promotions-april-4-debut-mma",
    title: "Longtime UFC vet Tony Ferguson ‘in talks’ with GFL ahead of promotion’s April 4 debut",
    date: "Jan 7, 2025, 4:01 PM EST",
    content: "Former Ultimate Fighting Championship (UFC) interim lightweight champion, Tony Ferguson, recently told his social media followers that fight news was “coming soon,” but it sounds like “El Cucuy” was not referring to a potential Octagon comeback.",
  },
    {
      src: "ctv",
      readMoreLink: "https://windsor.ctvnews.ca/windsor-fighter-randi-field-signs-to-newly-established-mma-league-1.7165080",
      title: "Windsor fighter Randi Field signs to newly established MMA league",
      date: "Jan 5, 2025",
      content: "After a fight in March 2023, Windsor MMA fighter Randi Field feared a long list of injuries would derail her career.",
    },
    {
      src: "usa",
      readMoreLink: "https://mmajunkie.usatoday.com/lists/global-fight-league-gfl-list-male-fighter-signings-ufc-mma-bellator-pfl",
      title: "Global Fight League's list of male fighter signings is long and distinguished",
      date: "Jan 5, 2025 at 9:15 AM ET",
      content: "The upstart Global Fight League recently announced a slew of female signings. And just like the women, the men signed to the new promotion is a who’s-who of former standouts.",
    },
    {
        src: "low",
        readMoreLink: "https://www.lowkickmma.com/ex-ufc-hannah-goldy-signs-global-fight-league/",
        title: "Ex-UFC Standout Hannah Goldy Signs with Global Fight League",
        date: "Jan 4, 2025",
        content: "Former UFC fighter Hannah Goldy has officially signed with the Global Fight League, marking a new chapter in her mixed martial arts career. The announcement comes as GFL prepares to launch its innovative team-based league in April 2025.",
      },
      {
        src: "sucka",
        readMoreLink: "https://mmasucka.com/bellator-champ-julia-budd-looking-to-turn-page-gfl-25/",
        title: "Former Bellator Champ Can Thrive in GFL If Given Chance",
        date: "Jan 4, 2025",
        content: "It’s been a while since we’ve seen Julia Budd suit up in action for an MMA fight. To find the last time that the former Bellator MMA Women’s Featherweight Champion set foot inside of a cage, you’d have to travel back in time to the night of June 16, 2023.",
      },
      {
        src: "sucka",
        readMoreLink: "https://mmasucka.com/crystal-ball-mma-2025-what-may-come-to-fruition/",
        title: "Crystal Ball Projections For 2025: Part Two of Two",
        date: "Jan 3, 2025",
        content: "Ready or not, 2025 is now in full swing. Now that we’re about three days into this journey that we call a new year, there are still a few more projections from the fabled crystal ball that have to be given out.",
      },
      {
        src: "mania",
        readMoreLink: "https://www.mmamania.com/2025/1/3/24335001/former-ufc-heavyweight-champion-cain-velasquez-signs-with-gfl-mma",
        title: "Former UFC heavyweight champion Cain Velasquez signs with GFL",
        date: "Jan 3, 2025 at 11:58 AM EST ",
        content: "Global Fight League (GFL), the new team-based MMA organization launching in April 2025, recently signed former Ultimate Fighting Championship (UFC) heavyweight champion, Cain Velasquez. The 42 year-old Californian will serve as Team Manager alongside longtime American Kickboxing Academy (AKA) coach, Javier Mendez.",
      },
      {
        src: "usa",
        readMoreLink: "https://mmajunkie.usatoday.com/lists/global-fight-league-female-women-roster-signings-hannah-goldy-randi-field-paige-vanzant",
        title: "Global Fight League's updated roster of female signings includes Julia Budd, Hannah Goldy, Randi Field and more",
        date: "Jan 3, 2025 at 5:35 PM ET",
        content: "The new Global Fight League will hold its inaugural draft later this month, and dozens of female fighters will be on the list.",
      },
      {
        src: "forbes",
        readMoreLink: "https://www.forbes.com/sites/brianmazique/2025/01/03/paige-vanzant-is-returning-to-mma-takes-perfect-opportunity/",
        title: "Paige VanZant Is Returning To MMA, Takes ‘Perfect Opportunity’",
        date: "Jan 3, 2025 at 01:03 AM EST",
        content: "Paige VanZant has participated in a variety of combat sports, but the 30-year-old has decided to return to MMA.",
      },
      {
        src: "nytt",
        readMoreLink: "https://www.mmanytt.com/latest-news/tyron-woodley-on-gfl-fighting-in-supermatches/",
        title: "Tyron Woodley on GFL: “FIGHTING IN SUPERMATCHES”",
        date: "Dec 19, 2024",
        content: "Tyron Woodley has confirmed his place in Global Fight League (GFL), a new MMA organization set to launch early next year.",
      },
      {
        src: "msn",
        readMoreLink: "https://www.msn.com/en-us/sports/mma_ufc/global-fight-league-everything-to-know-about-the-ufcs-newest-competitor/ar-AA1vUQAJ?apiversion=v2&noservercache=1&domshim=1&renderwebcomponents=1&wcseo=1&batchservertelemetry=1&noservertelemetry=1",
        title: "Global Fight League: Everything To Know About The UFC's Newest Competitor",
        date: "Dec 15, 2024",
        content: "MMA fans have another organization to look forward to as the Global Fight League (GFL) emerges as a fresh competitor to the UFC.",
      },
      {
        src: "tpg",
        readMoreLink: "https://evrimagaci.org/tpg/global-fight-league-aims-to-rival-ufc-dominance-90614?srsltid=AfmBOop-u0D3vTIVnRgIPSdaSrSlJ9x7ktwyuNlfbkcE6NKni5puWRJt",
        title: "Global Fight League Aims To Rival UFC Dominance",
        date: "Dec 15, 2024",
        content: "Innovative new promotion set to launch with diverse roster and bold vision for MMA's future.",
      },
      {
        src: "sportster",
        readMoreLink: "https://www.thesportster.com/global-fight-league-everything-to-know-about-the-ufcs-newest-competitor/",
        title: "Global Fight League Aims To Rival UFC Dominance",
        date: "Dec 15, 2024",
        content: "MMA fans have another organization to look forward to as the Global Fight League (GFL) emerges as a fresh competitor to the UFC.",
      },
      {
          src: "illus",
          readMoreLink: "https://www.si.com/fannation/mma/news/ufc-killer-new-league-signs-former-champs-stacked-roster-with-dozens-of-stars",
          title: "UFC-Killer? New League Signs Former Champs, Stacked Roster With Dozens of Stars",
          date: "Dec 13, 2024",
          content: "Everything you need to know about the Global Fight League (GFL).",
        },
        {
          src: "yahoo",
          readMoreLink: "https://uk.sports.yahoo.com/news/global-fight-league-reveals-massive-list-of-signings-including-8-ex-ufc-champs-for-new-team-based-mma-organization-201248109.html",
          title: "Global Fight League reveals massive list of signings, including 8 ex-UFC champs, for new team-based MMA organization",
          date: "Dec 12, 2024 at 4:08 PM EST",
          content: "The Global Fight League (GFL) is ready to take the MMA world by storm. GFL founder Darren Owen revealed Wednesday on Uncrowned's \"The Ariel Helwani Show\" that the promotion's first events will occur in April 2025.",
        },
        {
          src: "newsweek",
          readMoreLink: "https://www.newsweek.com/sports/multiple-former-ufc-stars-added-new-global-fight-league-organization-1999424",
          title: "Multiple Former UFC Stars Added to New Global Fight League Organization",
          date: "Dec 11, 2024 at 3:01 PM EST",
          content: "The MMA space is set to grow even more, as a new organization has been announced. That new organization is called the \"Global Fight League,\" and it is headed by Darren Owen.",
        },
        {
          src: "forbes",
          readMoreLink: "https://www.forbes.com/sites/brianmazique/2024/12/11/10-former-ufc-champions-headline-new-mma-organizations-roster/",
          title: "10 Former UFC Champions Headline New MMA Organization’s Roster",
          date: "Dec 11, 2024 at 06:45 PM EST",
          content: "The new team-based MMA organization was unveiled on The Ariel Helwani Show on Wednesday, December 11, and it was boosted by an interesting financial model that promises a 50-50 revenue split with athletes, and a roster packed with names MMA fans will immediately recognize.",
        },
        {
          src: "yahoo",
          readMoreLink: "https://sports.yahoo.com/global-fight-league-announces-launch-224513993.html",
          title: "Global Fight League announces launch, roster includes giant list of former UFC athletes",
          date: "Dec 11, 2024 at 5:45 PM EST",
          content: "A new player has entered the MMA landscape, and it has arrived with some big names.",
        },
        {
          src: "usa",
          readMoreLink: "https://mmajunkie.usatoday.com/2024/12/global-fight-league-launch-ufc-woodley-silva-werdum",
          title: "Global Fight League announces launch, roster includesgiant list of former UFC athletes",
          date: "Dec 11, 2024 at 5:45 PM EST",
          content: "A new player has entered the MMA landscape, and it has arrived with some big names. Wednesday, Global Fight League (GFL) announced its official launch will come in the opening weeks of 2025.",
        },
        {
          src: "yahoo",
          readMoreLink: "https://sports.yahoo.com/anthony-pettis-kevin-lee-explain-details-of-new-gfl-deals-i-couldnt-say-no-to-this-kind-of-contract-235530319.html",
          title: "Anthony Pettis, Kevin Lee explain details of new GFL deals: 'I couldn't say no to this kind of contract'",
          date: "Dec 11, 2024 at 6:45 PM EST",
          content: "MMA's newest organization Global Fight League (GFL) is officially set to debut in 2025.",
        },
        {
          src: "dog",
          readMoreLink: "https://www.sherdog.com/news/news/Global-Fight-League-Reveals-TeamBased-Format-Roster-with-Multiple-ExUFC-Champs-195692",
          title: "Global Fight League Reveals Team-Based Format, Roster with Multiple Ex-UFC Champs",
          date: "Dec 11, 2024",
          content: "On Wednesday, promotion founder Darren Owen announced plans to launch a team-based MMA league with a season format beginning in April 2025. According to a release, the league will feature six teams in “major global markets.”",
        },
];


const pressContent = [
  {
    src: "press6",
    readMoreLink: silverside,
    title: "Silverside AI Named Official AI Innovation Partner for Global Fight League (GFL)",
    date: "February 05, 2025",
    content: "Silverside AI, San Francisco-based artificial intelligence innovation lab, today announced its partnership with the Global Fight League (GFL), a new team-based MMA league, marking a groundbreaking collaboration that will revolutionize how combat sports are presented and promoted through AI technology. As GFL's official AI partner, Silverside will leverage cutting-edge artificial intelligence to create innovative content and experiences for the league's inaugural season. The partnership oﬃcially launched with the GFL Draft on January 24th, in which the GFL shared AI-generated athlete walkouts created using MiniMax AI technology, with a custom music track titled 6 Sides of the Cage. The campaign will also include videos introducing the identities for individual teams, launching later this year.",
  },
  {
    src: "press5",
    readMoreLink: boxtoBox,
    title: "Global Fight League Partners with Box to Box to Revolutionize MMA Storytelling for Inaugural 2025 Season",
    date: "February 04, 2025",
    content: "New York and Las Vegas, February 4, 2025 – The Global Fight League (GFL) today announced an exclusive partnership with Box to Box, the Emmy-winning production company behind sports storytelling hits such as “Formula 1: Drive to Survive,” “Faceoff: Inside the NHL,” and “Sprint.” In this new partnership, Box to Box will collaborate with the GFL on content creation and ideation, from a flagship docuseries to dynamic digital features, live event broadcasts, and in-depth athlete profiles into and through the first season, which will begin this coming April.",
  },
  {
    src: "press4",
    readMoreLink: gflDraft,
    title: "GLOBAL FIGHT LEAGUE UNVEILS DETAILS FOR FRIDAY’S VIRTUAL DRAFT",
    date: "January 24, 2025",
    content: "The Global Fight League (GFL), the new team-based MMA organization launching in April 2025, today announced additional details for the first-ever GFL Virtual Draft, which will take place this Friday, January 24th, at 8 PM EST. Fans worldwide catch tune in to watch the selection LIVE on MMA GFL’s official platforms: Instagram, Facebook, YouTube and Twitch, plus the show will also air on the social channels of DAZN and PPV.com, the Triller TV app, and Rumble.",
  },
  {
    src: "press3",
    readMoreLink: managersCoaches,
    title: "Global Fight League Unveils Six Inaugural Cities, Marquee Team Managers and Coaches, and More MMA Legends enter the GFL 2025 Draft",
    date: "January 16, 2025",
    content: "The Global Fight League (GFL) is proud to announce the six cities that will serve as the foundation for its inaugural...",
  },
    {
      src: "press2",
      readMoreLink: "https://www.prlog.org/13055090-global-fight-league-unveils-list-of-60-signed-women-athletes-ahead-of-jan-24-draft-first-season.html",
      title: "Global Fight League Unveils List of 60 Signed Women Athletes Ahead of Jan. 24 Draft, First Season",
      date: "Jan 3, 2025",
      content: "Women from 16 Countries - Including World Champions - to Share Revenue Pool with Male Teammates for the First Time in MMA History",
    },
    {
      src: "press1",
      readMoreLink: "https://www.prlog.org/13055100-global-fight-league-signs-top-male-athletes-ahead-of-draft.html",
      title: "Global Fight League Announces Initial Funding and Sets Launch Plan for New Team-Based MMA Property",
      date: "Dec 11, 2024 at 4:00 PM EST",
      content: "The Global Fight League, a new team-based MMA property, today announced that it has successfully closed a multi-million-dollar seed round and is set to launch its first season in April 2025.",
    },
];

export { imageMapping, newsContent, pressContent, videos };
